import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { useContentSections } from "../../hooks/content-sections"

type ListProps = {
  sectionPath: string
}

export const ContentSectionGridList: React.FC<ListProps> = ({ sectionPath }) => {
  const { getContentForPath } = useContentSections()
  const content = getContentForPath(sectionPath)

  if (content == null) {
    return null
  }

  const section = content.sections[content.sections.length - 1]

  const childSectionCards = section.childSections.map(({ title, iconPrefix, icon, path, synopsis }) => (
    <ContentCard key={title} title={title} iconPrefix={iconPrefix} icon={icon} url={path} excerpt={synopsis} />
  ))

  const articleCards = section.articles.map(({ title, iconPrefix, icon, path, excerpt }) => (
    <ContentCard key={title} title={title} iconPrefix={iconPrefix} icon={icon ?? "sparkles"} url={path} excerpt={excerpt} />
  ))

  const linkCards = section.links?.map(({ title, iconPrefix, icon, excerpt, url }) => (
    <ContentCard key={title} title={title} iconPrefix={iconPrefix} icon={icon ?? "sparkles"} url={url} excerpt={excerpt} external />
  ))

  const pageCards = section.pages?.map(({ title, iconPrefix, icon, path, excerpt }) => (
    <ContentCard key={title} title={title} iconPrefix={iconPrefix} icon={icon ?? "sparkles"} url={path} excerpt={excerpt} external />
  ))

  return (
    <div className="py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-12">
      {linkCards}
      {childSectionCards}
      {articleCards}
      {pageCards}
    </div>
  )
}

type ContentCardProps = {
  title: string
  iconPrefix?: IconPrefix
  icon: IconName
  url: string
  excerpt: string
  external?: boolean
}

export const ContentCard: React.FC<ContentCardProps> = ({ title, iconPrefix, icon, url, excerpt, external }) => {
  const content = (
    <>
      <div className="xl:hidden">
        <FontAwesomeIcon icon={[iconPrefix ?? "fal", icon]} size="3x" className="text-tangerine" />
      </div>
      <div className="hidden xl:block">
        <FontAwesomeIcon icon={[iconPrefix ?? "fal", icon]} size="4x" className="text-tangerine" />
      </div>
      <h2 className="text-lg xl:text-2xl font-rocgrotesk font-medium text-midnight-sky dark:text-white mt-4 xl:mt-6 mb-4">{title}</h2>
      <div className="text-base xl:text-lg">{excerpt}</div>
    </>
  )

  const cardStyling =
    "py-9 px-6 rounded-md border border-neutral-100 bg-white dark:bg-midnight-sky shadow-lg shadow-black/5 hover:ring hover:ring-neutral-100 hover:ring-offset-2 hover:ring-offset-white dark:hover:ring-offset-2 dark:hover:ring dark:hover:ring-light-navy dark:hover:ring-offset-dark-navy dark:border-none"

  return external ? (
    <a href={url} className={cardStyling}>
      {content}
    </a>
  ) : (
    <Link to={url} className={cardStyling}>
      {content}
    </Link>
  )
}
